import { BrowserRouter as Router, Route, Switch, match as MatchProps } from "react-router-dom";
import React, { useState, lazy, Suspense } from "react";

import LinkRoute from "./screen/link";
import Layout from "./screen/layout";
import Privacidade from "./screen/privacidade";
import FaleConosco from "./screen/faleconosco";
import TermoUso from "./screen/termouso";
import Sobre from "./screen/sobre";
import CountScreen from "./screen/count";
import { FirebaseProvider } from "./fireapp";

const Register = lazy(() => {
    return import("./screen/register");
});

const PostForm = lazy(() => {
    return import("./screen/posts");
});

const AnalyticScreen = lazy(() => {
    return import("./screen/analytics");
});

const AdsenseAdminScreen = lazy(() => {
    return import("./screen/adsense");
});

const ExcluirLink = lazy(() => {
    return import("./screen/excluirlink");
});

const WppApp: React.FC = () => {
    return (
        <Layout>
            <Router>
                <Suspense fallback={<div>CARREGANDO...</div>}>
                    <FirebaseProvider>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                component={Register}
                            />
                            <Route
                                path="/qtdlinks"
                                component={CountScreen}
                            />
                            <Route
                                path="/adsense-admin"
                                component={AdsenseAdminScreen}
                            />
                            <Route
                                path="/posts-admin"
                                component={PostForm}
                            />
                            <Route
                                path="/analytics"
                                component={AnalyticScreen}
                            />
                            <Route
                                path="/sobre"
                                component={Sobre}
                            />
                            <Route
                                path="/privacidade"
                                component={Privacidade}
                            />
                            <Route
                                path="/termouso"
                                component={TermoUso}
                            />
                            <Route
                                path="/faleconosco"
                                component={FaleConosco}
                            />
                            <Route
                                path="/excluirlink"
                                component={ExcluirLink}
                            />
                            <Route
                                path="/:link"
                                component={LinkRoute}
                            />
                        </Switch>
                    </FirebaseProvider>
                </Suspense>
            </Router>
        </Layout>
    );
};
export default WppApp;
