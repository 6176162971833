import React, { useContext, useState, useEffect, ReactElement, PropsWithChildren } from "react";
import { TextoComponent } from "../component/texto";
// import { appContext } from '../fireapp';
// import PostComponent from '../component/post';

import "../App.scss";

import logoPng from "../media/logo.png";
import whatsHeroPng from "../media/whats-hero.png";
import linkSoltoPng from "../media/img_link_solto.png";
import statsSoltoPng from "../media/img_stats_solto.png";
import seguroSoltoPng from "../media/img_seguro_solto.png";

const texts = [
    `
    <p class="_ngcontent-twm-24"><strong class="_ngcontent-twm-24 has-text-white">Para criar um link que redirecione diretamente para sua janela de WhatsApp sem necessidade de cadastrar seu n&uacute;mero na agenda do telefone, siga os seguintes passos:</strong></p>
    <ol class="_ngcontent-twm-24">
    <li class="_ngcontent-twm-24">Acesse a p&aacute;gina inicial do <strong><a title="Pagina Inicial para criar um WhatsApp Link" href="http://whats.link">WhatsLink</a></strong></li>
    <li class="_ngcontent-twm-24">Preencha corretamente seu Nome, Telefone com DDD, e nome do link</li>
    <li class="_ngcontent-twm-24">Click em <strong class="has-text-white">GERAR LINK</strong> e pronto! Seu link foi criado!</li>
    </ol>
    <p className="_ngcontent-twm-24">
        Voc&ecirc; deve memorizar ou copiar o link gerado e utilizar no
        seu site ou redes sociais, e toda vez que alguem clicar neste
        link, ser&aacute; redirecionado diretamente para sua janela do
        WhatsApp, tornando muito mais r&aacute;pido e pr&aacute;tico o
        contato com voc&ecirc;.
    </p>
    <p class="_ngcontent-twm-24">&nbsp;</p>
    <h4 class="option-h4 _ngcontent-twm-24">Inserindo seu Link em suas Redes Sociais</h4>
    <p class="_ngcontent-twm-24"><strong class="has-text-white">Aprenda a deixar seu link funcional nos principais locais de acesso:</strong></p>
    <ol class="_ngcontent-twm-24">
    <li class="_ngcontent-twm-24">Instagram</li>
    <li class="_ngcontent-twm-24"><strong class="has-text-white"><a title="bcard - tenha todos os seus links em uma unica pagina" href="http://bcard.me">BCARD</a></strong> (&Aacute;rvore de Links)</li>
    <li class="_ngcontent-twm-24">Demais P&aacute;ginas (site, blogs)</li>
    </ol>
    <p class="_ngcontent-twm-24"><span class="_ngcontent-twm-30"><strong class="has-text-white">Op&ccedil;&atilde;o 1-</strong>&nbsp;Para que seu link se torne funcional (clic&aacute;vel) no instagram voc&ecirc; deve coloca-lo no campo <strong class="has-text-white">SITE</strong>, dentro do <strong class="has-text-white">EDITAR PERFIL</strong> no seu instagram, siga o tutorial:<br /><br />Click no EDITAR em seu instagram para editar seu perfil e incluir seu WhatsApp Link, como na imagem abaixo:</span></p>
    <p class="_ngcontent-twm-24"><span class="_ngcontent-twm-30"><img style="display: block; margin-left: auto; margin-right: auto;" src="https://ap.imagensbrasil.org/images/2020/01/23/link-whatsapp-instagram-1.jpg" alt="" width="226" height="469" /><br /><br />Insira o link criado no campo SITE, dentro do seu perfil.<br /><br /><img style="display: block; margin-left: auto; margin-right: auto;" src="https://ap.imagensbrasil.org/images/2020/01/23/link-whatsapp-instagram-2.jpg" alt="inserindo link do whatsapp clicavel no instagram" width="231" height="413" /><br /><br />Agora basta clicar no bot&atilde;o concluir! <strong class="has-text-white">PRONTO</strong>, a bio do seu instagram agora possui o link que leva seus seguidores diretamente para sua janela de chat do WhatsApp, tornando muito pr&aacute;tico o contato com voc&ecirc;!</span></p>
    <p class="_ngcontent-twm-24">&nbsp;</p>
    <p class="_ngcontent-twm-24"><span class="_ngcontent-twm-30"><strong class="has-text-white">Op&ccedil;&atilde;o 2-</strong>&nbsp;Voc&ecirc; pode criar uma p&aacute;gina com todos os seus links (Link do Whatsapp, Canal do YouTube, Site, Blog, etc..) usando a ferramente <strong class="has-text-white"><a title="criar cart&atilde;o de visita virtual gr&aacute;tis" href="http://bcard.me">BCARD</a></strong> , &eacute; um cart&atilde;o de visita virtual, totalmente gr&aacute;tis.</span></p>
    <p class="_ngcontent-twm-24">&nbsp;Antes de tudo, voc&ecirc; deve acessar <a href="HTTP://BCARD.ME">HTTP://BCARD.ME</a>&nbsp;e fazer um cadastro, n&atilde;o se preocupe, pois &eacute; uma ferramenta totalmente gr&aacute;tis!<br /><br /><a title="criar um link tree" href="http://bcard.me"><img style="display: block; margin-left: auto; margin-right: auto;" src="https://i.ibb.co/0ZHm54j/bcard-1.jpg" alt="criar cart&atilde;o de visita virtual" width="228" height="460" /></a><br /><br />Quando voc&ecirc; concluir seu cadastro, siga os passos abaixo para ter um link clic&aacute;vel dentro do seu <a title="criar pagina de links gratis" href="http://bcard.me"><strong class="has-text-white">BCARD</strong></a> que direciona para seu WhatsApp.<br /><br />Acesse sua conta do <a title="criar linktree cartao de visitas" href="http://bcard.me"><strong class="has-text-white">BCARD</strong></a> e click no botao +.<br /><br /><img style="display: block; margin-left: auto; margin-right: auto;" src="https://i.ibb.co/nL062dH/criar-link-tree-bcard-2.jpg" alt="inserir link do whatsapp no cart&atilde;o de visitas" width="235" height="473" /><br /><br /><br /></p>
    <p class="_ngcontent-twm-24">Insira o nome do bot&atilde;o, selecione o icone desejado e o link que voc&ecirc; criou no <strong class="has-text-white">WhatsLink</strong></p>
    <p class="_ngcontent-twm-24"><strong class="has-text-white"><a title="gerador de link do whatsapp" href="http://whats.link"><img style="display: block; margin-left: auto; margin-right: auto;" src="https://i.ibb.co/q5XrcfB/criar-cartao-virtual-gratis-3.jpg" alt="inserir link do whatsapp no link tree bcard" width="235" height="459" /></a></strong></p>
    <p class="_ngcontent-twm-24">&nbsp;</p>
    <p class="_ngcontent-twm-24">Agora click no bot&atilde;o ATUALIZAR e pronto! Seu <strong class="has-text-white">BCARD</strong> j&aacute; esta com o seu <strong class="has-text-white">WhatsApp Link</strong> prontinho para ser clicado! N&atilde;o foi bem f&aacute;cil? Veja abaixo como ficou!<br /><br /><img style="display: block; margin-left: auto; margin-right: auto;" src="https://i.ibb.co/3TTSHXQ/Whats-App-Image-2020-01-23-at-19-52-14.jpg" alt="bcard cart&atilde;o de visita virtual gratis" width="240" height="520" /></p>
    <p class="_ngcontent-twm-24">&nbsp;</p>
    <p class="_ngcontent-twm-24"><strong class="has-text-white"><span class="_ngcontent-twm-30">Op&ccedil;&atilde;o 3 -&nbsp;<br /><br /></span></strong>Para inserir seu WhatsApp Link em um site ou blog via html, basta inserir o c&oacute;digo abaixo no seu site ou blog:<br /><br />&lt;a href="http://whats.link/<strong class="has-text-white">SEULINKAQUI</strong>"&gt;Oi, fale diretamente comigo pelo WhatsApp clicando aqui!&lt;/a&gt;<br /><br />Se quiser saber mais sobre WhatsLink, BCARD e outros produtos da<em> Nuvem, inc.</em> entre em contato conosco <a title="cart&atilde;o virtual gr&aacute;tis da nuvem" href="http://bcard.me/nuvem"><strong class="has-text-white">CLICANDO AQUI</strong></a></p>
    `,
];

interface Post {
    key: string;
    post: {
        title: string;
        desc: string;
        createdAt: string;
    };
    content: string;
}

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    let text = texts[0];
    const path = window.location.pathname === "/";

    // const app = useContext(appContext);

    // const [posts, setPosts] = useState<Array<Post>>([]);
    // const rotas = ["/qtdlinks","/adsense-admin","/posts-admin","/analytics","/sobre","/privacidade","/termouso","/faleconosco","/excluirlink"];

    // const fetchNews = async (app: firebase.app.App|undefined) => {
    //     if (!path && !rotas.find(x => x === window.location.pathname)){

    //     if (!app) return;
    //     const database = app.database();
    //     const posts: Post[] = [];
    //     const postsRandom: Post[] = [];
    //     const ds = await database.ref('posts').limitToLast(20).once('value');

    //     ds.forEach(d => {

    //         const p = Object.assign({},{
    //             key: d.key,
    //         }, d.val());
    //         posts.push(p);
    //     });
    //     let spliceIndex;
    //     for (let index = 0; index < 3; index++) {
    //         spliceIndex = Math.floor(Math.random() * posts.length);
    //         const element = posts[spliceIndex];
    //         posts.splice(spliceIndex,1);
    //         postsRandom.push(element);
    //     }

    //     setPosts(postsRandom);
    // }
    // }

    // useEffect(() => {
    //     fetchNews(app);
    // }, [app]);

    return (
        <div className="app">
            <div className="hero is-medium whatslink">
                <div className="hero-head">
                    <div className="container">
                        <div className="navbar">
                            <div className="navbar-brand">
                                <a
                                    href="/"
                                    className="navbar-item navbar-logo">
                                    <img
                                        src={logoPng}
                                        alt="logo"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hero-body nopadding">{children}</div>

                {path ? (
                    <div className="hero-body">
                        <div className="container">
                            <TextoComponent />
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {/* <div className="hero-body">

                {posts.length > 0 ? (
                    <h3 className="is-size-3 blog has-text-white has-text-weight-bold">Blog WhatsLink</h3>
                ) : ("")}   
        
                {(posts && posts.length > 0) && posts.map(p => 
                    <div key={p.key} className="has-text-white container-help container-news" >
                        <PostComponent post={p} />
                    </div>
                )}
                </div> */}

                {/* <div className="hero-footer has-background-primary	">
                    <div className="container">


                        <div className="columns is-vcentered is-desktop detail">
                            <div className="column is-narrow">
                                <img src={whatsHeroPng} className="image is-256x256" />
                            </div>
                            <div className="column">
                                <p className="has-text-white has-text-justified">
                                    O WhatsApp Link é uma ferramenta grátis para direcionar usuários diretamente para sua janela de chat do WhatsApp sem ter a necessidade de cadastrar o número na agenda do telefone, tornando mais rápido e prático o contato com você e/ou seu negócio.
                            </p>
                            </div> 
                        </div>
                    </div>
                </div> */}

                <div className="hero-footer has-background-primary	">
                    <div className="container">
                        <div className="columns is-vcentered is-desktop detail">
                            <div className="column is-narrow">
                                <img
                                    src={whatsHeroPng}
                                    className="image is-256x256"
                                />
                            </div>
                            <div className="column">
                                <p className="has-text-white has-text-justified">
                                    O gerador de link para Whatsapp What's Link é uma ferramenta grátis para direcionar
                                    usuários diretamente para sua janela de chat do WhatsApp sem ter a necessidade de
                                    cadastrar o número na agenda do telefone, tornando mais rápido e prático o contato
                                    com você e/ou seu negócio.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hero whatslink-why">
                <div className="hero-body section">
                    <div className="container">
                        <div className="columns detail">
                            <div className="column">
                                <div className="columns is-vcentered">
                                    <div className="column">
                                        <img
                                            src={linkSoltoPng}
                                            alt=""
                                            className="image is-128x128"
                                        />
                                    </div>
                                    <div className="column">
                                        <div className="content has-text-weight-bold is-size-5">
                                            <p>Compartilhe seu contato através de um link curto ao invés do número.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns is-vcentered">
                                    <div className="column">
                                        <img
                                            src={statsSoltoPng}
                                            alt=""
                                            className="image is-128x128"
                                        />
                                    </div>
                                    <div className="column">
                                        <div className="content has-text-weight-bold is-size-5">
                                            <p>Veja quantas pessoas falaram com você através do seu link.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns is-vcentered">
                                    <div className="column">
                                        <img
                                            src={seguroSoltoPng}
                                            alt=""
                                            className="image is-128x128"
                                        />
                                    </div>
                                    <div className="column">
                                        <div className="content has-text-weight-bold is-size-5">
                                            <p>Totalmente grátis e seguro.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                {/* <img src={'https://firebasestorage.googleapis.com/v0/b/wpplink.appspot.com/o/screen-phone.png?alt=media&token=bbcffddc-430e-40a7-af21-5f04b95fdca3'} alt="" className="image phone" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="hero is-medium whatslink">
            <div className="hero-footer has-background-primary	">
                    <div className="container">


                        <div className="columns is-vcentered is-desktop detail">
                            <div className="column is-narrow">
                                <img src={whatsHeroPng} className="image is-256x256" />
                            </div>
                            <div className="column">
                                <p className="has-text-white has-text-justified">
                                    O WhatsApp Link é uma ferramenta grátis para direcionar usuários diretamente para sua janela de chat do WhatsApp sem ter a necessidade de cadastrar o número na agenda do telefone, tornando mais rápido e prático o contato com você e/ou seu negócio.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>   */}

            <footer className="footer has-background-primary">
                <div className="container">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="content has-text-white">
                                    <p>2021 © Nuvem Inc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <div className="content has-text-white">
                                    <p>
                                        <a
                                            className="has-text-white"
                                            href="/sobre">
                                            {" "}
                                            Sobre{" "}
                                        </a>{" "}
                                        -{" "}
                                        <a
                                            className="has-text-white"
                                            href="/privacidade">
                                            {" "}
                                            Privacidade{" "}
                                        </a>
                                        -{" "}
                                        <a
                                            className="has-text-white"
                                            href="/termouso">
                                            {" "}
                                            Termo de Uso{" "}
                                        </a>{" "}
                                        -{" "}
                                        <a
                                            className="has-text-white"
                                            href="/faleconosco">
                                            {" "}
                                            Contato{" "}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
