import React from "react";

export class AdsTopoComponent extends React.Component {
    // async componentDidMount() {

    //     try {
    //         if(window) (window.adsbygoogle = window.adsbygoogle || []).push({
    //             google_ad_client: "ca-pub-8011765208303106",
    //             // google_ad_test: "on",
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    async componentDidMount() {
        try {
            if (window) (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const styleBloco = {
            display: "inline-block",
            width: "728px",
            height: "90px",
        };
        return (
            <ins
                className="adsbygoogle"
                style={styleBloco}
                data-ad-client="ca-pub-2241525781831625"
                data-ad-slot="9215843995"></ins>
            // <ins className="adsbygoogle"
            //     style={{ display: 'block' }}
            //     data-ad-client="ca-pub-8011765208303106"
            //     data-ad-slot="2393735332"
            //     data-ad-format="auto"
            //     data-full-width-responsive="true"></ins>
            // data-ad-test="on"
        );
    }
}
