import React, { useContext, useState, useEffect } from "react";
import { appContext } from "../fireapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import NotFoundComponent from "../component/not-found";
import PostComponent from "../component/post";
import MetaTags from "react-meta-tags";

import { AdsCleverCore, AdsComponent } from "../component/ads";
import { AdsTopoComponent } from "../component/adsTopo";
import { AdsComponent as AdsComponentCustom } from "../component/ads-custom";
import { AdsComponent as AdsCustomComponentTopo } from "../component/ads-custom-topo";
import { child, get, getDatabase, ref } from "firebase/database";

interface Link {
    nome: string;
    telefone: string;
    apelido: string;
    mensagem: string;
    descricao: string;
}

interface Post {
    post: {
        title: string;
        desc: string;
        createdAt: string;
    };
    content: string;
}
interface LinkRouteProps {
    match: {
        params: {
            [k: string]: string;
        };
    };
}
const DOMAINDB = process.env.REACT_APP_DATABASE || "";

const LinkDetail: React.FC<{ link: Link }> = ({ link }) => {
    let text = "";

    if (link.mensagem !== undefined) {
        text = `&text=${link.mensagem}`;
    }

    const url = `https://api.whatsapp.com/send?phone=${link.telefone}` + text;

    return (
        <div>
            <h3 className="subtitle has-text-weight-semibold has-text-white"> Deseja falar com: </h3>
            <h1 className="title has-text-weight-semibold has-text-white"> {link.nome} </h1>
            <h3 className="subtitle has-text-weight-semibold has-text-white">{link.descricao}</h3>
            <p>
                <a
                    href={url}
                    className="button is-large is-primary is-rounded">
                    Enviar Mensagem
                </a>
            </p>
        </div>
    );
};

interface LinkQuery {
    founded: boolean;
    link: Link;
    posts: string[];
}

const LinkScreen: React.FC<LinkRouteProps> = ({ match }) => {
    const app = useContext(appContext);

    const [result, setResult] = useState<LinkQuery>();

    const [posts, setPosts] = useState<Array<Post>>([]);
    const fetchNews = async function (l: LinkQuery) {
        if (!app) return;

        if (!l.founded) return;

        if (!l.posts) {
            return;
        }

        const database = getDatabase(app);

        const works: Promise<Post>[] = l.posts.map(async (id) => {
            // return database.ref('posts').child(id).once('value')
            //     .then(ds => ds.val())
            return get(child(ref(database, "posts"), id)).then((ds) => ds.val());
        });

        const foundedPosts = await Promise.all(works);

        setPosts(foundedPosts);
    };
    const fetchLink = async function (): Promise<LinkQuery> {
        const link = { apelido: "", nome: "", telefone: "", mensagem: "" };
        if (!app) {
            return {
                founded: false,
                link: {
                    apelido: "",
                    nome: "",
                    telefone: "",
                    mensagem: "",
                    descricao: "",
                },
                posts: [],
            };
        }
        const database = getDatabase(app);
        // const ds = await database.ref()
        // // .ref('domains')
        // // .child(DOMAINDB)
        // .child('links').child(match.params.link).once('value');
        const ds = await get(child(ref(database, "links"), match.params.link));
        const postsIds: string[] = [];
        // ds.child('posts').forEach( p => {
        //     if (p.key) postsIds.push(p.key);
        // })
        const res = {
            founded: ds.exists(),
            link: ds.val(),
            posts: postsIds,
        };
        setResult(res);
        return res;
    };
    useEffect(() => {
        fetchLink().catch((err) => {
            console.log(err);
        });
    }, [app]);
    return (
        <React.Fragment>
            <MetaTags>
                <meta
                    property="og:title"
                    content={result ? (result.founded ? result.link.nome : "WhatsApp Link") : "WhatsApp Link"}
                />
                <meta
                    property="og:description"
                    content={
                        result
                            ? result.founded
                                ? result.link.descricao
                                : "Crie um link direto para seu WhatsApp."
                            : "Crie um link direto para seu WhatsApp."
                    }
                />
            </MetaTags>
            <AdsCustomComponentTopo />
            <div className="has-text-centered">
                <AdsTopoComponent />
            </div>
            <div className="container">
                <div className="columns link-view">
                    <div className="column has-text-centered is-half-desktop is-offset-one-quarter-desktop">
                        {result ? (
                            result.founded ? (
                                <LinkDetail link={result.link} />
                            ) : (
                                <NotFoundComponent link={match.params.link} />
                            )
                        ) : (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                size="lg"
                                className="has-text-white is-size-1"
                                spin
                                pulse
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="container ads">
                <AdsComponent />

                <AdsCleverCore />
            </div>

            {/* <div className="container ads">
            <AdsComponentCustom />
        </div>   */}
            <AdsComponentCustom />
            {/* <div className="container ads">
            { (result && result.founded) ? 
                <AdsComponent /> : null
            }
            
        </div> */}
            {/* <div className="hero-body">
            <div className="container">
                <h3 className="is-size-3 blog has-text-white has-text-weight-bold">Blog WhatsLink</h3>
                {posts.map(p => 
                    <div className="has-text-white container-help container-news" >
                        <PostComponent post={p} />
                    </div>
                )}
            </div>
        </div> */}

            <div className="container">
                <div className="columns link-view">
                    <div className="has-text-justified is-offset-one-quarter-desktop">
                        <h3 className="is-size-3 blog has-text-white has-text-weight-bold has-text-right">
                            Pra que serve o <b className="is-size-2">WhatsLink</b>?{" "}
                        </h3>
                        <br></br>
                        <div className="has-text-white container-help container-news">
                            <p>
                                O Encurtador de link WhatsLink foi pensado para facilitar a vida de quem usa o WhatsApp,
                                sem destinção de nicho. A ferramenta é ideal para sua loja virtual e-comerce, redes
                                sociais como a queridinha do momento tiktok, facebook, linkedin, instagram, e para
                                agregadores de link como Bcard e linketree (clicavel bcard e linktree). Ideal para
                                qualquer negócio online, sendo um atalho muito prático para qualquer pessoa que tem
                                interesse em seu produto ou serviço, seja ele qual for, ou ate mesmo para uso pessoal,
                                para que seus amigos se conectem a você de forma mais rápida, tornando bem mais facil de
                                memorizar seu WhatsLink do que seu numero de smartphone.
                            </p>
                             
                            <p>
                                Você pode usar a criatividade e fazer do seu link um endereço curto e prático para
                                qualquer pessoa falar com você, utilizando ate em cartões de visita da sua empresa, seja
                                ela virtual ou física, sendo totalmente grátis você personaliza sua identidade na rede
                                mais utilizada para envio de mensagens instantâneas, o WhatsApp.
                            </p>
                            <br></br>
                            <p>
                                Não importa se você tem uma loja de roupas femininas, masculinas, infantil ou unisex,
                                loja de celulares, loja de móveis planejados, material de construção, super mercado ou
                                se você é um profissional como dentista, advogado, psicologo, médico, arquiteto,
                                engenheiro, mecânico, programador, digital influencer, ou se você quer apenas facilitar
                                a vida dos seus amigos ao se comunicar com você, o gerador de link curto para whatsapp é
                                personalizado e perfeito para qualquer situação!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LinkScreen;
